<template>
  <div class="core-block">
    <img v-if="isMobile" class="bg" src="/assets/index/img/core/bg-h5.png" alt="" />
    <img v-else class="bg" src="/assets/index/img/core/bg.png" alt="" />

    <div class="core-inner">
      <div class="keywords">
        <vue-danmaku
          v-if="isMobile"
          :danmus="coreData.keywords"
          loop
          randomChannel
          :top="30"
          :right="40"
          :channels="5"
          extraStyle="display: inline-block;
          font-size: 0.16rem;line-height: 0.24rem;padding: 0.06rem 0.12rem;margin: 0.1rem;
          font-weight: 700;
          background: url(/assets/index/img/core/keyword-bg.png);
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: 0 center;
          white-space: nowrap;"
          :speeds="50"
          style="height: 100%; width: 100%"
        >
        </vue-danmaku>
        <vue-danmaku
          v-else
          :danmus="coreData.keywords"
          loop
          randomChannel
          :top="24"
          :right="100"
          :channels="6"
          extraStyle="display: inline-block;
          padding: 18px 22px;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          background: url(/assets/index/img/core/keyword-bg.png);
          background-repeat: no-repeat;
          background-size: auto 100%;
          background-position: 0 center;
          white-space: nowrap;
          margin: 12px;"
          :speeds="60"
          style="height: 100%; width: 100%"
        >
        </vue-danmaku>
      </div>
      <img v-if="!isMobile" class="cover-left" src="/assets/index/img/core/cover-left.png" alt="" />
      <img v-if="!isMobile" class="cover-right" src="/assets/index/img/core/cover-right.png" alt="" />
      <div class="titles">
        <div :class="['item', 'item-' + styleIndex[index]]" v-for="(item, index) in coreData.titles" :key="item.title">
          <img class="title-pic" :src="`/assets/index/img/core/title-${index + 1}.png?0818`" :alt="item.title" />
          <div class="title-text">{{ item.title }}</div>
        </div>
      </div>
      <img v-if="!isMobile" class="ip" src="/assets/index/img/core/ip.png?0818" alt="" />
    </div>
  </div>
</template>
<script>
import vueDanmaku from 'vue-danmaku';
export default {
  components: {
    vueDanmaku,
  },
  props: {
    coreData: {
      type: Object,
      default: () => ({
        titles: [],
        keywords: [],
      }),
    },
  },
  data() {
    return {
      isMobile: window.innerWidth < 991,
      styleIndex: [0, 1, 2, 3, 4, 5],
      stylePc: '',
      styleH5: '',
    };
  },
  mounted() {
    setInterval(() => {
      this.changePosition();
    }, 4000);
  },
  methods: {
    changePosition: function () {
      this.styleIndex = this.styleIndex.sort(() => 0.5 - Math.random());
      // console.log(71, this.styleIndex);
    },
  },
};
</script>
<style scoped lang="less">
.core-block {
  max-width: 1920px;
  min-height: 500px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  color: #fff;
  // .keywords {
  //   position: absolute;
  //   top: 55%;
  //   left: 0;
  //   transform: translateY(-50%);
  //   width: 100%;
  //   height: 495px;
  // }

  .bg {
    height: 100%;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: 0 auto;
    .bg {
      width: 100%;
      height: auto;
    }
  }
  .core-inner {
    width: 85.652%;
    max-width: 1424px;
    margin: 0 auto;
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    @media (max-width: 991px) {
      width: 100%;
    }
    .ip {
      position: absolute;
      bottom: 0;
      right: 10px;
      width: 163px;
    }
    .cover-left {
      position: absolute;
      width: 300px;
      top: 236px;
      left: 50%;
      margin-left: -270px;
    }
    .cover-right {
      position: absolute;
      position: absolute;
      width: 328px;
      top: 190px;
      right: -100px;
    }
    .titles {
      position: absolute;
      top: 50%;
      left: 0;
      height: 560px;
      width: 600px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      .title-pic {
        width: 188px;
      }
      .title-text {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 83px;
        font-weight: 700;
        font-size: 28px;
        line-height: 33px;
        color: #393939;
        text-align: center;
        @media (max-width: 991px) {
          bottom: 0.4rem;
          font-size: 0.16rem;
          line-height: 0.16rem;
        }
      }
      .item {
        position: absolute;
        -webkit-transition: 0.7s all ease-out;
        transition: 0.7s all ease-out;

        &.item-0 {
          top: 91px;
          left: 3px;
        }
        &.item-1 {
          top: 265px;
          left: -95px;
        }
        &.item-2 {
          top: 265px;
          left: 104px;
        }
        &.item-3 {
          top: 265px;
          left: 306px;
        }
        &.item-4 {
          top: 436px;
          left: 4px;
        }
        &.item-5 {
          top: 436px;
          left: 205px;
        }
      }
      @media (max-width: 991px) {
        width: 100%;
        height: 3rem;
        .title-pic {
          width: 0.94rem;
        }
        .item {
          &.item-0 {
            top: 2.38rem;
            left: 0.88rem;
          }
          &.item-1 {
            top: 3.24rem;
            left: 0.38rem;
          }
          &.item-2 {
            top: 3.24rem;
            left: 1.41rem;
          }
          &.item-3 {
            top: 3.24rem;
            left: 2.44rem;
          }
          &.item-4 {
            top: 4.15rem;
            left: 0.88rem;
          }
          &.item-5 {
            top: 4.15rem;
            left: 1.92rem;
          }
        }
      }
    }
    .keywords {
      position: absolute;
      top: 55%;
      right: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 495px;
      width: 60%;
      overflow: hidden;

      .item {
        display: inline-block;
        padding: 18px 22px;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        background: url(/assets/index/img/core/keyword-bg.png);
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 0 center;
        white-space: nowrap;
        margin: 12px;
      }
      @media (max-width: 991px) {
        top: 1.63rem;
        -webkit-transform: none;
        transform: none;
        width: 100%;
        height: 2.8rem;
        .item {
          font-size: 0.16rem;
          line-height: 0.24rem;
          padding: 0.06rem 0.12rem;
          margin: 0.1rem;
        }
      }
    }
  }
}
</style>
