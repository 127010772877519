<!-- 828首页 -->
<template>
  <div class="page-index">
    <banner-block :list="indexData.banner"></banner-block>

    <div class="video-block">
      <!-- <h1>活动宣传视频</h1> -->
      <div class="video-box">
        <img v-if="indexData.video_poster" :src="`/assets/index/video/${indexData.video_poster}`" alt="" />
        <video-player
          v-if="isLoad"
          ref="video"
          class="video-player vjs-big-play-centered"
          :playsinline="true"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
        >
        </video-player>
      </div>
    </div>

    <div :class="[fadeView_core && 'fade-in']">
      <core-block :core="indexData.core" :coreData="indexData.core"></core-block>
    </div>

    <div :class="['inner-view', fadeView_servers && 'fade-in']" id="servers" style="padding-bottom: 0">
      <div class="inner-view-title" style="border: none">
        <h2>企业服务专区</h2>
      </div>
      <servers-block :servers="indexData.servers" :business="indexData.business"></servers-block>
    </div>

    <div :class="['recommend-block', fadeView_recommend && 'fade-in']">
      <recommend-block :recommend="indexData.newproduct"></recommend-block>
    </div>

    <div :class="['inner-view', fadeView_events && 'fade-in']" id="events">
      <div class="inner-view-title" style="border-top: none">
        <h2>精彩活动</h2>
      </div>
      <events-block :events="indexData.events"></events-block>
      <!-- <div class="inner-view-bm">
        <div class="more-line common-828-btn" @click="$router.push('/events/area')">查看更多</div>
      </div> -->
    </div>
    <!-- <div :class="['inner-view', fadeView_mutualAid && 'fade-in']" id="mutualAid">
      <div class="inner-view-title">
        <h2>服务中心</h2>
      </div>
      <mutual-aid-block :list="indexData.mutualAid"></mutual-aid-block>
      <div class="inner-view-bm">
        <div class="more-line common-828-btn" @click="$router.push('/mutual-aid')">查看更多</div>
      </div>
    </div> -->
    <div :class="['inner-view', fadeView_live && 'fade-in']">
      <div class="inner-view-title">
        <h2>最新直播</h2>
      </div>
      <div class="live-box">
        <live-block></live-block>
      </div>
      <!-- <div class="inner-view-bm" v-if="indexData.live_more">
        <div class="more-line common-828-btn" @click="$router.push('/events/live')">查看更多</div>
      </div> -->
    </div>
    <!-- <div :class="['inner-view', fadeView_5 && 'fade-in']">
      <div class="inner-view-title">
        <h2>成功案例</h2>
      </div>
      <case-block></case-block>
      <div class="inner-view-bm">
        <div class="more-line common-828-btn" @click="$router.push('/case')">查看更多</div>
      </div>
    </div> -->
    <!-- <div :class="['inner-view', fadeView_6 && 'fade-in']">
      <div class="inner-view-title">
        <h2>政策支持</h2>
      </div>
      <policy-block></policy-block>
      <div class="inner-view-bm">
        <div class="more-line common-828-btn" @click="$router.push('/policy')">查看更多</div>
      </div>
    </div> -->
    <div :class="['inner-view', fadeView_news && 'fade-in']" id="news">
      <div class="inner-view-title">
        <h2>新闻资讯</h2>
      </div>
      <news-block></news-block>
      <div class="inner-view-bm" v-if="indexData.news_more">
        <div class="more-line common-828-btn" @click="$router.push('/news')">查看更多</div>
      </div>
    </div>
  </div>
</template>
<script>
import bannerBlock from './components/banner';
import coreBlock from './components/core';
import serversBlock from './components/servers';
import recommendBlock from './components/recommend';
import eventsBlock from './components/events';
// import mutualAidBlock from './components/mutualAid';
import liveBlock from './components/live';
// import caseBlock from './components/case';
// import policyBlock from './components/policy';
import newsBlock from './components/news';
import { changeWxShare, setWxConfig } from '@/utils/wx';

let scrollTop = 0;
export default {
  components: {
    serversBlock,
    coreBlock,
    recommendBlock,
    eventsBlock,
    // mutualAidBlock,
    liveBlock,
    // caseBlock,
    // policyBlock,
    newsBlock,
    bannerBlock,
  },
  data() {
    return {
      isMobile: window.innerWidth < 991,
      indexData: {},
      isPlaying: false,
      fadeView_servers: 0,
      fadeView_events: 0,
      fadeView_live: 0,
      fadeView_news: 0,
      fadeView_recommend: 0,
      fadeView_core: 1,
      fadeView_mutualAid: 1,
      lastTime: 0,
      playerOptions: {},
      isLoad: false,
    };
  },
  created() {
    window.addEventListener('scroll', this.windowScrolistener);
  },
  async mounted() {
    await this.query();
    await setWxConfig(window.wx);
    await changeWxShare(window.wx, {
      title: this.indexData.share.title,
      desc: this.indexData.share.desc,
      link: window.location.href,
      imgUrl: window.location.origin + '/assets/index/img/' + this.indexData.share.imgUrl,
    });
    // fadeInElements = Array.from(document.getElementsByClassName('fade-in'));
    // console.log(11, fadeInElements);
    // fadeInElements.map((item) => {
    //   console.log(119, item.offsetTop);
    // });
  },
  // watch: {
  //   $route(d) {
  //     // console.log(d);
  //     if (d.hash && d.hash != '#') {
  //       this.goAnchor(d.hash);
  //     }
  //   },
  // },
  methods: {
    goAnchor(id) {
      document.querySelector(id).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    },
    async query() {
      await fetch('/assets/index/data.json?' + Math.random()).then((d) => {
        d.json().then((res) => {
          this.indexData = res;
          this.playerOptions = {
            // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, // 如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: 'zh-CN',
            // aspectRatio: '9:16', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                src: `/assets/index/video/${res.video}`, // url地址
              },
            ],
            hls: true,
            poster: `/assets/index/video/${res.video_poster}`, // 你的封面地址
            width: document.documentElement.clientWidth, // 播放器宽度
            // height: '100%',
            notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              volumePanel: false, //音量面板
              progressControl: false, //进度条
              // timeDivider: false, //时间分割器
              // durationDisplay: false, //持续时间显示
              remainingTimeDisplay: false, //剩余时间显示
              // currentTimeDisplay: false, //当前时间展示
              fullscreenToggle: true, // 全屏按钮
            },
          };
          this.isLoad = true;
        });
      });
    },
    onPlayerPause(player) {
      console.log('player pause!', player);
    },
    onPlayerEnded: function (player) {
      console.log('视频播放完::', player);
    },
    onPlayerPlay(player) {
      console.log('player play!', player);
      this.$hwa.sendAction('index_indexvideo_btn_click');
    },
    playVideo: function () {
      console.log(this.$refs.videoPlayer);
      this.isPlaying = true;
    },
    windowScrolistener: function () {
      // let isMobile = window.innerWidth < 991;
      scrollTop = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(123, scrollTop, this.isMobile);
      if (scrollTop > 1000 && this.isPlaying && this.isMobile) {
        this.pauseVideo();
      }
      if (scrollTop > 1900 && !this.fadeView_servers) {
        this.fadeView_servers = 1;
      }
      if (scrollTop > 3000 && !this.fadeView_recommend) {
        this.fadeView_recommend = 1;
      }
      if (scrollTop > 3940 && !this.fadeView_events) {
        this.fadeView_events = 1;
      }
      if (scrollTop > 4740 && !this.fadeView_live) {
        this.fadeView_live = 1;
      }
      if (scrollTop > 5840 && !this.fadeView_news) {
        this.fadeView_news = 1;
      }
    },
  },
};
</script>
<style scoped lang="less" src="./assets/layout.less" />
<style lang="less" scoped>
.page-index {
  min-height: 2000px;
  .recommend-block,
  .inner-view {
    -webkit-transform: translateY(250px);
    transform: translateY(250px);
    -webkit-transition: 0.7s all ease-out;
    transition: 0.7s all ease-out;
    @media (max-width: 991px) {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    &.fade-in {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}

::v-deep {
  .video-js .vjs-big-play-button {
    width: 1.63332em;
    border-radius: 50%;
    margin-left: -0.81666em;
    background: rgba(255, 255, 255, 0.2);

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
    width: 0.6rem !important;
    height: 0.6rem !important;
    background-size: 100% 100% !important;
    background-image: url(./assets/img/play.png) !important;
    margin: -0.3rem 0 0 -0.3rem !important;
    border: none;
    .vjs-icon-placeholder {
      display: none;
    }
    @media screen and(min-width: 1080px) {
      width: 120px !important;
      height: 120px !important;
      margin: -60px 0 0 -60px !important;
    }
  }
}
.video-box {
  position: relative;
  .video-player,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    //   -webkit-media-controls-play-button {
    //     display: none;
    //   }
    //   /deep/.vjs-big-play-button {

    //     height: 64px;
    //     width: 64px;
    //     margin-left: -32px;
    //     border: none;
    //     // border-radius: 50%;
    //     // background: #fff;
    //     background: url(./assets/img/play.png);
    //     background-size: 100% 100%;
    //     .vjs-icon-placeholder {
    //       display: none;
    //       // color: #e6002e;
    //       // display: block;
    //     }
    //   }
  }
}
</style>
