<template>
  <div class="servers-block" v-if="servers.length > 1">
    <div class="tab-hd">
      <div @mouseover="mouseoverFn(index)" :class="['item', index === tabIndex && 'cur']" v-for="(item, index) in servers" :key="index">
        <div class="th" @click.stop="tabIndex = tabIndex === index ? '' : index">
          <div>{{ item.title }}</div>
          <div v-if="isMobile" class="iconfont icon-arrowdown"></div>
          <img v-if="isMobile" class="ip" src="/assets/index/img/ip.png?0814" alt="" />
        </div>
        <div class="td">{{ item.desc }}</div>
        <div class="tab-bd" v-if="isMobile">
          <div class="news-box">
            <a
              :href="item.news.link"
              target="_blank"
              rel="noopener"
              v-track="{
                eventType: 'index_product_btn_click',
                params: {
                  product_name: item.product.name,
                },
              }"
            >
              <img class="bg" :src="`/assets/index/img/${item.news.img}`" alt="" />
              <div class="title">
                <div>{{ item.news.title }}</div>
              </div>
            </a>
          </div>
          <div class="product-box">
            <a
              :href="item.product.link"
              target="_blank"
              rel="noopener"
              v-track="{
                eventType: 'index_product_btn_click',
                params: {
                  product_name: item.product.name,
                },
              }"
            >
              <div class="logo-box">
                <div class="logo">
                  <img :src="`/assets/index/img/${item.product.logo}`" alt="" />
                </div>
                <div>{{ item.product.name }}</div>
              </div>
              <div class="title">{{ item.product.title }}</div>
              <div class="desc">{{ item.product.desc }}</div>
              <div class="tags">
                <div v-for="tag in item.product.tag" :key="tag">{{ tag }}</div>
              </div>
              <div class="more"><span class="iconfont icon-arrowright"></span></div>
            </a>
            <a
              class="more-line common-828-btn"
              v-track="{
                eventType: 'index_product_btn_click',
                params: {
                  product_name: item.product.name,
                },
              }"
              :href="item.link"
              target="_blank"
              rel="noopener"
              >查看更多</a
            >
          </div>
        </div>
      </div>
      <img class="ip" :style="{ left: tabIndex * 25 + '%' }" src="/assets/index/img/ip.png?0814" alt="" />
    </div>
    <div class="tab-bd" v-if="!isMobile">
      <div class="news-box">
        <a
          :href="servers[tabIndex].news.link"
          target="_blank"
          rel="noopener"
          v-track="{
            eventType: 'index_product_btn_click',
            params: {
              product_name: servers[tabIndex].product.name,
            },
          }"
        >
          <img class="bg" :src="`/assets/index/img/${servers[tabIndex].news.img}`" alt="" />
          <div class="title">
            <div>{{ servers[tabIndex].news.title }}</div>
          </div>
        </a>
      </div>
      <div class="product-box">
        <a
          :href="servers[tabIndex].product.link"
          v-track="{
            eventType: 'index_product_btn_click',
            params: {
              product_name: servers[tabIndex].product.name,
            },
          }"
          target="_blank"
          rel="noopener"
        >
          <div class="logo-box">
            <div class="logo">
              <img :src="`/assets/index/img/${servers[tabIndex].product.logo}`" alt="" />
            </div>
            <div>{{ servers[tabIndex].product.name }}</div>
          </div>
          <div class="title">{{ servers[tabIndex].product.title }}</div>
          <div class="desc">{{ servers[tabIndex].product.desc }}</div>
          <div class="tags">
            <div v-for="tag in servers[tabIndex].product.tag" :key="tag">{{ tag }}</div>
          </div>
          <div class="more" style="background: #fff"><span class="iconfont icon-arrowright"></span></div>
        </a>
        <a
          class="more-line common-828-btn"
          v-track="{
            eventType: 'index_product_btn_click',
            params: {
              product_name: servers[tabIndex].product.name,
            },
          }"
          :href="servers[tabIndex].link"
          target="_blank"
          rel="noopener"
          >查看更多</a
        >
      </div>
    </div>

    <div class="recommend">
      <img class="label-bg" v-if="isMobile" src="../assets/img/item-label-bg.png" alt="" />

      <div class="item-label">
        <img class="label-bg" v-if="!isMobile" src="../assets/img/item-label-bg.png" alt="" />
        <div class="info">
          <div class="title">{{ business.title }}</div>
          <a :href="business.link" target="_blank" rel="noopener">
            <!-- <div class="more">查看更多</div> -->
          </a>
        </div>
      </div>
      <div class="list">
        <swiper class="swiper" :options="isMobile ? swiperOptionH5 : swiperOption">
          <swiper-slide class="item" v-for="(item, index) in business.list" :key="index">
            <a
              :href="item.link"
              v-track="{
                eventType: 'index_provider_logo_click',
                params: {
                  company_name: item.name,
                },
              }"
              target="_blank"
              rel="noopener"
            >
              <!-- <div class="cover"></div> -->
              <div class="info">
                <img class="logo" :src="`/assets/index/img/${item.logo}?0818`" alt="" />
                <!-- <div class="title">{{ item.name }}</div> -->
              </div>
            </a>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    servers: {
      type: Array,
      default: () => [
        {
          product: {},
          news: {
            img: '',
          },
        },
      ],
    },
    business: {
      type: Object,
      default: () => ({
        link: '',
      }),
    },
  },
  data() {
    return {
      // list: [],
      isMobile: window.innerWidth < 991,
      tabIndex: 0,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 16,
        // loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'bullet-active',
          bulletClass: 'my-bullet',
        },
      },
      swiperOptionH5: {
        slidesPerView: 2,
        slidesPerColumn: 2,
        spaceBetween: 8,
        // loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'bullet-active',
          bulletClass: 'my-bullet',
        },
      },
      links: ['/startup', '/industry-ec', '/go-global', '/zjtx'],
    };
  },
  mounted() {},
  methods: {
    mouseoverFn: function (index) {
      if (!this.isMobile) this.tabIndex = index;
    },
  },
};
</script>
<style scoped lang="less">
.swiper-container {
  overflow-y: visible;
  height: 100%;
  .swiper-pagination {
    margin-left: -142px;
    @media (max-width: 991px) {
      margin-left: 0;
      bottom: 5px;
    }
  }
}
.my-bullet {
  width: 8px;
  height: 1px;
  background: #d9d9d9;
  border-radius: 4px;
  display: inline-block;
  margin: 0 4px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.bullet-active {
  width: 24px;
  background: #e6002e;
}
@media (max-width: 991px) {
  .my-bullet {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin: 0 4px;
  }
  .bullet-active {
    height: 4px;
    width: 16px;
    background: #e6002e;
  }
}
.servers-block {
  @media (max-width: 991px) {
    background: #f7f8fa;
  }
  .tab-hd {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    @media (max-width: 991px) {
      min-height: 190px;
    }

    .ip {
      position: absolute;
      width: 27px;
      top: 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      margin-left: 118px;
      @media (max-width: 991px) {
        opacity: 0;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }
    }

    .item {
      width: 25%;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      color: #9197ae;
      font-weight: 400;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;

      &.cur {
        // color: #e6002e;
        .th {
          background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          border-color: #e6002e;
          color: #e6002e;

          .ip {
            @media (max-width: 991px) {
              display: inline-block;
              opacity: 1;
            }
          }
        }
        .td {
          color: #2a2a2a;
        }
      }
      .th {
        font-weight: 700;
        font-size: 28px;
        line-height: 1;
        padding-bottom: 16px;
        border-bottom: 1px solid #9197ae;
        margin-right: 1px;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        position: relative;
        .ip {
          @media (max-width: 991px) {
            display: none;
            width: 0.16rem;
            margin-left: 0.7rem;
            top: 0.15rem;
          }
        }
      }
      .td {
        padding-top: 16px;
        padding-right: 48px;
        font-size: 18px;
        line-height: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }
    }
    @media (max-width: 991px) {
      display: block;
      .item {
        width: 100%;
        .th {
          font-weight: 700;
          font-size: 0.16rem;
          padding: 0.16rem;
          color: #2a2a2a;
          border-bottom: 0.5px solid #e8e9eb;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .iconfont {
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
          }
        }
        .td {
          font-size: 0.12rem;
          line-height: 0.18rem;
          padding: 0.13rem 0.16rem;
          display: none;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          background-color: #fff;
        }
        &.cur {
          .th {
            // color: #e6002e;
            border-bottom: 0.5px solid #e8e9eb;
            .iconfont {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
              -webkit-text-fill-color: #e6002e;
              color: #e6002e;
            }
          }
          .td,
          .tab-bd {
            visibility: visible;
            height: auto;
            opacity: 1;
            padding: 0.24rem 0.16rem 0;
          }
        }
      }
    }
  }
  .tab-bd {
    padding: 60px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    @media (max-width: 991px) {
      padding: 0 0.16rem;
      height: 0;
      opacity: 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      visibility: hidden;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .news-box {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 66.5%;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
      @media (max-width: 991px) {
        width: 100%;
      }
      a:hover {
        .bg {
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
        .title {
          // background-color: #e6002e;
          &::before {
            opacity: 1;
          }
        }
      }
      .bg {
        width: 100%;
        vertical-align: top;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }
      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 24px;
        color: #fff;

        &::before {
          display: block;
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          background: linear-gradient(252.44deg, rgba(27, 44, 133, 0.5) 0%, rgba(224, 1, 22, 0.5) 100%);
          -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
        }

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          position: relative;
        }
        @media (max-width: 991px) {
          font-size: 0.12rem;
          line-height: 1;
          padding: 0.12rem;
          background: linear-gradient(252.44deg, rgba(27, 44, 133, 0.5) 0%, rgba(224, 1, 22, 0.5) 100%);
          -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
        }
      }
    }
    .product-box {
      a {
        display: block;
        padding: 32px 32px 32px 50px;
        background: #f7f8fa;
        border-radius: 0 12px 12px 0;
        &:hover {
          .title,
          .desc {
            // color: #e6002e;
            background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            color: #e6002e;
          }
          .more {
            opacity: 1;
          }
        }
      }
      @media (max-width: 991px) {
        padding-top: 0.12rem;
        padding-bottom: 0.24rem;
        a {
          background: #ffffff;
          border: 0.5px solid #e8e9eb;
          border-radius: 8px;
          padding: 0.2rem 0.16rem;
        }
      }
      .logo-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
        color: #2a2a2a;
        .logo {
          -ms-flex-negative: 0;
          flex-shrink: 0;
          width: 44px;
          height: 44px;
          background: #ffffff;
          border: 1px solid #e8e9eb;
          border-radius: 4px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-right: 8px;

          img {
            width: 100%;
          }
        }
        @media (max-width: 991px) {
          font-size: 0.14rem;
          .logo {
            width: 0.24rem;
            height: 0.24rem;
          }
          .title {
            font-size: 0.16rem;
            padding: 0.16rem 0.16rem 0;
          }
        }
      }
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 1;
        margin: 24px 0;
        color: #2a2a2a;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        @media (max-width: 991px) {
          font-size: 0.16rem;
          padding: 0.16rem 0 0;
        }
      }
      .desc {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @media (max-width: 991px) {
          font-size: 0.12rem;
          line-height: 0.18rem;
          padding: 0.05rem 0 0;
        }
      }
      .tags {
        padding-top: 24px;
        div {
          display: inline-block;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #999;
          padding: 4px 8px;
          border: 1px solid #dedfe0;
          border-radius: 2px;
          margin-right: 8px;
        }
      }
      .more {
        opacity: 0;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        margin-top: 48px;
        @media (max-width: 991px) {
          opacity: 1;
          float: right;
          margin-top: -0.2rem;
        }
      }
      .more-line {
        margin-left: 50px;
        margin-top: 24px;
        display: inline-block;
        border: 1px solid #e6002e;
        border-radius: 19px;
        padding: 0 20px;
        font-size: 14px;
        line-height: 38px;
        color: #e6002e;
        cursor: pointer;
        background: none;
        @media (max-width: 991px) {
          display: block;
          width: 0.91rem;
          text-align: center;
          font-size: 0.12rem;
          padding: 0;
          line-height: 0.28rem;
          margin: 0.24rem auto 0;
          border-radius: 1rem;
        }
      }
    }
  }

  .recommend {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    @media (max-width: 991px) {
      min-height: 300px;
    }
    .label-bg {
      position: absolute;
      height: 100%;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .item-label {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 228px;
      height: 148px;
      background: #e6002e;
      background: linear-gradient(270.69deg, #fc395c 1.19%, #e50012 100%);
      border: 1px solid #ffffff;
      border-radius: 12px;
      position: relative;

      .info {
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        color: #fff;
      }
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
      }
      .more {
        margin-top: 24px;
        display: inline-block;
        background-color: #e6002e;
        border-radius: 19px;
        padding: 0 20px;
        font-size: 14px;
        line-height: 38px;
        color: #fff;
        cursor: pointer;
      }
    }
    @media (max-width: 991px) {
      display: block;
      background: #151515;
      background: linear-gradient(270.69deg, #fc395c 1.19%, #e50012 100%);
      padding-bottom: 0.1rem;
      .item-label {
        text-align: center;
        width: 100%;
        height: auto;
        background: none;
        border: 0;
        padding-bottom: 0.16rem;

        .info {
          position: relative;
          -webkit-transform: none;
          transform: none;
          .title {
            font-size: 0.16rem;
            line-height: 1;
            padding-top: 0.24rem;
          }
          a {
            display: none;
          }
        }
      }
    }
    .list {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      height: 200px;
      overflow: auto;
      margin-left: 16px;
      white-space: nowrap;
      @media (max-width: 991px) {
        height: auto;
        margin: 0 16px;
        border-radius: 12px;
        overflow: hidden;
        .swiper-container {
          padding-bottom: 30px;
        }
        .item {
          height: 100px;
          // .info {
          //   bottom: 0.16rem;
          //   left: 0.16rem;
          // }
          .title {
            font-size: 0.14rem;
            line-height: 1;
            padding-top: 0.12rem;
          }
          .logo {
            max-width: 95%;
            height: 0.26rem;
          }
        }
      }
    }
    .item {
      width: 272px;
      height: 148px;
      border-radius: 12px;
      margin-right: 16px;
      position: relative;
      display: inline-block;
      vertical-align: top;
      background: #ffffff;
      border: 1px solid #e8e9eb;
      border-radius: 12px;
      a {
        color: #2a2a2a;
        display: block;
        overflow: hidden;
        position: relative;
        height: 100%;
        border-radius: 12px;
      }
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      // box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.09);

      &:hover {
        -webkit-box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.09);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.09);

        // .logo {
        //   height: 40px;
        // }
        // .cover {
        //   opacity: 1;
        //   visibility: visible;
        //   backdrop-filter: blur(16px);
        //   background: rgba(0, 0, 0, 0.5);
        // }
      }
      .bg {
        width: 100%;
        height: 100%;
        vertical-align: top;
      }
      .cover {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        // opacity: 0;
      }
      .info {
        width: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
      }
      .logo {
        height: 55px;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        max-width: 80%;
        -o-object-fit: contain;
        object-fit: contain;
      }
      .title {
        padding-top: 24px;
        font-weight: 700;
        font-size: 28px;
        line-height: 28px;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }
    }
  }
}
</style>
