<template>
  <div class="live-block">
    <ul>
      <li v-for="(item, index) in list.liveList" :class="index == curIndex ? 'cur' : 'item'" :key="item.title">
        <a
          @mouseover="mouseoverFn(index)"
          target="_blank"
          :href="item.link"
          v-track="{
            eventType: 'index_living_meeting_click',
            params: {
              meeting_name: item.title,
            },
          }"
        >
          <img :src="`/assets/live/img/${item.img}`" class="bg" />
          <div class="date">
            <template v-if="isMobile && index !== curIndex">
              {{ item.date }}
              <div>{{ item.time }}</div>
            </template>
            <template v-else>
              {{ item.date }}<span v-if="index == curIndex">{{ ' ' + item.week + ' ' + item.time }}</span>
            </template>
          </div>
          <div class="info">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
            <div class="bm">
              <div class="l">
                <div class="iconfont icon-zhibo"></div>
                <div>{{ item.link_desc }}</div>
              </div>
              <div class="more" v-if="item.link"><span class="iconfont icon-arrowright"></span></div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {},

  data() {
    return {
      list: [],
      curIndex: 0,
      isMobile: document.documentElement.clientWidth < 768,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      fetch('/assets/live/data.json?' + Math.random()).then((d) => {
        d.json().then((res) => {
          this.list = res;
        });
      });
    },
    mouseoverFn: function (index) {
      console.log(index);
      this.curIndex = index;
    },
  },
};
</script>
<style scoped lang="less">
.live-block {
  ul {
    li {
      background: #ffffff;
      border: 1px solid #e8e9eb;
      border-radius: 12px;
      overflow: hidden;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;

      .bg {
        opacity: 0;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        height: 100%;
      }
      a {
        display: block;
        height: 100%;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        color: #2a2a2a;
      }
      &.cur {
        height: 3.54rem;
        min-height: 300px;
        -webkit-backdrop-filter: blur(16px);
        backdrop-filter: blur(16px);
        margin-bottom: 0.1rem;
        margin-top: 0.1rem;
        .bg {
          display: block;
          vertical-align: top;
          opacity: 1;
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
        .date {
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 0.14rem;
          line-height: 0.26rem;
          background: rgba(255, 255, 255, 0.7);
          background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
          color: #fff;
          border-radius: 0px 0px 12px 0px;
          padding: 0 24px;
          div {
            display: none;
          }
        }
        .info {
          z-index: 1;
          position: absolute;
          bottom: 0.14rem;
          left: 0.14rem;
          right: 0.14rem;
          .title {
            font-weight: 700;
            font-size: 0.16rem;
            line-height: 1;
            color: #fff;
          }
          .desc {
            padding-top: 20px;
            color: rgba(255, 255, 255, 0.7);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            font-size: 0.12rem;
            line-height: 0.18rem;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .bm {
            opacity: 1;
            padding-top: 40px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            .l {
              position: relative;
              color: #fff;
              font-size: 12px;
              // padding-left: 0.2rem;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              .iconfont {
                font-size: 20px;
                margin-right: 8px;
              }
              // &::after {
              //   position: absolute;
              //   content: '';
              //   width: 0.11rem;
              //   height: 0.07rem;
              //   left: 0;
              //   top: 50%;
              //   margin-top: -0.035rem;
              //   background-size: 100% 100%;
              //   background-image: url('./assets/img/Subtract.png');
              // }
            }
            .more {
              position: relative;
              // background: #e6002e;
              // border-radius: 19px;
              // padding: 0 0 0 0.15rem;
              // font-size: 0.12rem;
              // line-height: 0.28rem;
              // color: #fff;
              // &::after {
              //   position: absolute;
              //   content: '';
              //   width: 0.045rem;
              //   height: 0.07rem;
              //   right: 0.15rem;
              //   top: 50%;
              //   margin-top: -0.035rem;
              //   background-size: 100% 100%;
              //   background-image: url('./assets/img/Vector.png');
              // }
            }
          }
        }
        a {
          &::after {
            display: block;
            content: '';
            width: 100%;
            height: 100%;
            background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      &.item {
        min-height: 50px;
        border-bottom: none;
        border-top: none;
        border-radius: 0;
        &:nth-child(2) {
          border-top: 1px solid #e8e9eb;
          border-top-left-radius: 0.1rem;
          border-top-right-radius: 0.1rem;
          .date {
            &::before {
              top: 0.1rem;
              bottom: 0;
            }
          }
        }
        &:nth-child(4) {
          border-bottom: 1px solid #e8e9eb;
          border-bottom-left-radius: 0.1rem;
          border-bottom-right-radius: 0.1rem;
          .date {
            &::before {
              bottom: 0.1rem;
            }
          }
        }
        a {
          padding-left: 1rem;
        }
        img {
          display: none;
        }
        .date {
          z-index: 1;
          position: absolute;
          top: 0;
          left: 0;
          width: 1rem;
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          padding-left: 0.4rem;
          // align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          line-height: 1.4;
          color: #e6002e;
          &::before {
            position: absolute;
            content: '';
            left: 0.2rem;
            top: 0;
            bottom: 0;
            background: #e6002e;
            width: 1px;
          }
          &::after {
            position: absolute;
            content: '';
            left: 0.145rem;
            top: 50%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            margin-top: -0.06rem;
            width: 0.12rem;
            height: 0.12rem;
            border-radius: 50%;
            background: #fff;
            border: 1px solid #e6002e;
          }
        }
        .info {
          padding: 0.14rem;
          .title {
            font-weight: 700;
            font-size: 0.16rem;
            line-height: 1;
            color: #2a2a2a;
          }
          .desc {
            font-weight: 400;
            font-size: 0.12rem;
            line-height: 0.12rem;
            color: #666;
            padding-top: 0.1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .bm {
            display: none;
          }
        }
      }
    }
  }
  @media screen and(min-width:768px) {
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      li {
        width: 20%;
        height: 540px;
        background: #ffffff;
        border: 1px solid #e8e9eb;
        border-radius: 12px;
        overflow: hidden;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;

        .bg {
          opacity: 0;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
          height: 100%;
        }
        a {
          display: block;
          height: 100%;
          position: relative;
          border-radius: 12px;
          overflow: hidden;
          color: #2a2a2a;
        }
        &.cur {
          width: 36.8%;
          height: 540px;
          -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
          margin: 0;
          .bg {
            display: block;
            vertical-align: top;
            opacity: 1;
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
          }
          .date {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 18px;
            line-height: 48px;
            background: rgba(255, 255, 255, 0.7);
            background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
            border-radius: 0px 0px 12px 0px;
            padding: 0 24px;
          }
          .info {
            z-index: 1;
            position: absolute;
            bottom: 32px;
            left: 32px;
            right: 32px;
            .title {
              font-weight: 700;
              font-size: 28px;
              line-height: 42px;
              color: #fff;
            }
            .desc {
              padding-top: 20px;
              color: rgba(255, 255, 255, 0.7);
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              font-size: 14px;
              line-height: 22px;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .bm {
              opacity: 1;
              padding-top: 40px;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              .l {
                color: #fff;
                font-size: 18px;
                padding: 0;
                &::after {
                  display: none;
                }
              }
              .more {
                // background: #e6002e;
                // border-radius: 19px;
                // padding: 0 20px;
                // font-size: 14px;
                // line-height: 38px;
                // color: #fff;
                &::after {
                  display: none;
                }
              }
            }
          }
          a {
            &::after {
              display: block;
              content: '';
              width: 100%;
              height: 100%;
              background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        &.item {
          border-bottom: 1px solid #e8e9eb;
          border-top: 1px solid #e8e9eb;
          border-radius: 12px;
          &:nth-child(2) {
            border-top: 1px solid #e8e9eb;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            .date {
              &::before {
                top: 0.1rem;
                bottom: 0;
              }
            }
          }
          &:nth-child(4) {
            border-bottom: 1px solid #e8e9eb;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .date {
              &::before {
                bottom: 0.1rem;
              }
            }
          }
          img,
          .bm {
            display: block;
          }
          a {
            padding: 0;
          }
          .date {
            display: inline-block;
            z-index: 1;
            position: absolute;
            color: #2a2a2a;
            top: 0;
            width: auto;
            left: 0;
            font-size: 18px;
            line-height: 48px;
            height: 48px;
            background: rgba(255, 255, 255, 0.7);
            border-radius: 0px 0px 12px 0px;
            padding: 0 24px;
            -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);

            &::before {
              display: none;
            }
            &::after,
            div {
              display: none;
            }
          }
          .info {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            width: 77%;
            padding: 0;
            .title {
              font-weight: 700;
              font-size: 28px;
              line-height: 42px;
              color: #2a2a2a;
            }
            .desc {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #666;
              padding-top: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .bm {
              opacity: 0;
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
