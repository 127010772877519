export default [
  {
    name: 'ent-gen',
    path: '/ent-gen',
    component: () => import('../views/product'),
    meta: {
      title: '企业通用',
    },
    children: [
      {
        path: '/ent-gen/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'big-data',
    path: '/big-data',
    component: () => import('../views/product'),
    meta: {
      title: 'AI&大数据',
    },
    children: [
      {
        path: '/big-data/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'basic',
    path: '/basic',
    component: () => import('../views/product'),
    meta: {
      title: '基础软件应用&服务',
    },
    children: [
      {
        path: '/basic/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'hardware',
    path: '/hardware',
    component: () => import('../views/product'),
    meta: {
      title: '硬件云服务',
    },
    children: [
      {
        path: '/hardware/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'innovation',
    path: '/innovation',
    component: () => import('../views/product'),
    meta: {
      title: '创新应用&元宇宙',
    },
    children: [
      {
        path: '/innovation/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'saas-pkg',
    path: '/saas-pkg',
    component: () => import('../views/product'),
    meta: {
      title: 'SaaS Package',
    },
    children: [
      {
        path: '/saas-pkg/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'manufacture',
    path: '/manufacture',
    component: () => import('../views/product'),
    meta: {
      title: '制造&专精特新',
    },
    children: [
      {
        path: '/manufacture/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'edu',
    path: '/edu',
    component: () => import('../views/product'),
    meta: {
      title: '教育',
    },
    children: [
      {
        path: '/edu/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'internet',
    path: '/internet',
    component: () => import('../views/product'),
    meta: {
      title: '互联网&文娱',
    },
    children: [
      {
        path: '/internet/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'cross-border',
    path: '/cross-border',
    component: () => import('../views/product'),
    meta: {
      title: '跨境电商&企业出海',
    },
    children: [
      {
        path: '/cross-border/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'new-retail',
    path: '/new-retail',
    component: () => import('../views/product'),
    meta: {
      title: '新零售',
    },
    children: [
      {
        path: '/new-retail/:id',
        component: () => import('../views/product'),
      },
    ],
  },
  {
    name: 'ent-service',
    path: '/ent-service',
    component: () => import('../views/product'),
    meta: {
      title: '企业服务',
    },
    children: [
      {
        path: '/ent-service/:id',
        component: () => import('../views/product'),
      },
    ],
  },
];
