<template>
  <div class="events-block" v-if="events.announce.title">
    <div class="tr">
      <div class="item item-announce">
        <a
          v-if="events.announce.link"
          :href="events.announce.link"
          target="_blank"
          v-track="{
            eventType: 'index_activity_btn_click',
            params: {
              meeting_name: events.announce.title,
            },
          }"
          rel="noopener"
        >
          <img :src="`/assets/index/img/${events.announce.img}`" alt="" class="bg" />
          <div class="cover"></div>
          <div class="info">
            <div class="title">{{ events.announce.title }}</div>
            <div class="desc">{{ events.announce.desc }}</div>
            <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
          </div>
        </a>
        <a v-else href="javascript:;" style="cursor: auto">
          <img :src="`/assets/index/img/${events.announce.img}`" alt="" class="bg" />
          <div class="cover"></div>
          <div class="info">
            <div class="title">{{ events.announce.title }}</div>
            <div class="desc">{{ events.announce.desc }}</div>
            <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
          </div>
        </a>
      </div>
      <div class="td-2">
        <div class="item">
          <a
            v-if="events.opening.link"
            :href="events.opening.link"
            v-track="{
              eventType: 'index_activity_btn_click',
              params: {
                city_name: events.opening.title,
              },
            }"
            target="_blank"
            rel="noopener"
          >
            <img :src="`/assets/index/img/${isMobile ? events.opening.imgH5 : events.opening.img}`" alt="" class="bg" />
            <div class="cover"></div>
            <div class="info">
              <div class="title">{{ events.opening.title }}</div>
              <div class="desc">{{ events.opening.desc }}</div>
              <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
            </div>
          </a>
          <a v-else href="javascript:;" style="cursor: auto">
            <img :src="`/assets/index/img/${isMobile ? events.opening.imgH5 : events.opening.img}`" alt="" class="bg" />
            <div class="cover"></div>
            <div class="info">
              <div class="title">{{ events.opening.title }}</div>
              <div class="desc">{{ events.opening.desc }}</div>
              <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
            </div>
          </a>
        </div>
        <div class="item">
          <a
            v-if="events.area.link"
            :href="events.area.link"
            target="_blank"
            v-track="{
              eventType: 'index_activity_btn_click',
              params: {
                city_name: events.area.title,
              },
            }"
          >
            <img :src="`/assets/index/img/${isMobile ? events.area.imgH5 : events.area.img}`" alt="" class="bg" />
            <div class="cover"></div>
            <div class="info">
              <div class="title">{{ events.area.title }}</div>
              <div class="desc">{{ events.area.desc }}</div>
              <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
            </div>
          </a>
          <a v-else href="javascript:;" style="cursor: auto">
            <img :src="`/assets/index/img/${isMobile ? events.area.imgH5 : events.area.img}`" alt="" class="bg" />
            <div class="cover"></div>
            <div class="info">
              <div class="title">{{ events.area.title }}</div>
              <div class="desc">{{ events.area.desc }}</div>
              <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
            </div>
          </a>
        </div>
        <template v-for="(site, index) in events.area.site">
          <div class="item" v-if="index < (isMobile ? events.area.site.length + 1 : 2)" :key="site.link + index">
            <a
              v-if="site.link"
              :href="site.link"
              target="_blank"
              v-track="{
                eventType: 'index_activity_btn_click',
                params: {
                  city_name: site.name,
                },
              }"
            >
              <img :src="`/assets/index/img/${isMobile ? site.imgH5 : site.img}`" alt="" class="bg" />
              <div class="cover"></div>
              <div class="info site">
                <div class="tag">828地方站</div>
                <div class="title">{{ site.name }}</div>
                <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
              </div>
            </a>
            <a v-else href="javascript:;" style="cursor: auto">
              <img :src="`/assets/index/img/${isMobile ? site.imgH5 : site.img}`" alt="" class="bg" />
              <div class="cover"></div>
              <div class="info site">
                <div class="tag">828地方站</div>
                <div class="title">{{ site.name }}</div>
                <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
              </div>
            </a>
          </div>
        </template>
      </div>
    </div>
    <div class="tr" v-if="!isMobile">
      <template v-for="(site, index) in events.area.site">
        <div class="item" v-if="index > 1" :key="index">
          <a
            v-if="site.link"
            :href="site.link"
            target="_blank"
            v-track="{
              eventType: 'index_activity_btn_click',
              params: {
                city_name: site.name,
              },
            }"
          >
            <img :src="`/assets/index/img/${site.img}`" alt="" class="bg" />
            <div class="cover"></div>
            <div class="info site">
              <div class="tag">828地方站</div>
              <div class="title">{{ site.name }}</div>
              <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
            </div>
          </a>
          <a v-else href="javascript:;" style="cursor: auto">
            <img :src="`/assets/index/img/${site.img}`" alt="" class="bg" />
            <div class="cover"></div>
            <div class="info site">
              <div class="tag">828地方站</div>
              <div class="title">{{ site.name }}</div>
              <div class="more" style="opacity: 0; margin-top: 0"><span class="iconfont icon-arrowright"></span></div>
            </div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    events: {
      type: Object,
      default: () => ({
        announce: { link: '' },
        opening: { link: '' },
        area: { link: '' },
      }),
    },
  },

  data() {
    return {
      isMobile: window.innerWidth < 991,
    };
  },
};
</script>
<style scoped lang="less">
.events-block {
  .tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -8px;
    @media (max-width: 991px) {
      margin: 0;
    }
    .td-2 {
      width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      @media (max-width: 991px) {
        width: 100%;
        padding: 0.08rem 0.12rem;
        .item {
          .desc {
            -webkit-line-clamp: 2;
          }
          .info.site {
            bottom: auto;
          }
        }
      }
      .item {
        width: 50%;
        &:nth-child(2) {
          margin-right: 0;
        }
      }
    }
    @media (max-width: 991px) {
      // display: block;
    }
    &:last-child {
      .item {
        &:last-child {
          .info {
            .tag {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  .item {
    width: 25%;
    padding: 0 8px 16px;
    min-height: 130px;
    @media (max-width: 991px) {
      width: 50%;
      padding: 0 0.04rem 0.08rem;
    }
    &.item-announce {
      min-height: 250px;
      width: 50%;
      padding-bottom: 16px;
      @media (max-width: 991px) {
        width: auto;
        padding: 0 0.16rem;
        text-align: center;
      }
    }
    a {
      color: #fff;
      display: block;
      overflow: hidden;
      position: relative;
      height: 100%;
      border-radius: 12px;
    }
    .bg {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
    .cover {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      -webkit-backdrop-filter: blur(16px);
      backdrop-filter: blur(16px);
      opacity: 0;
    }
    .info {
      position: absolute;
      left: 32px;
      right: 32px;
      bottom: 60px;
      -webkit-transform: translatey(100%);
      transform: translatey(100%);
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      &.site {
        bottom: 98px;
      }
      .desc,
      .more {
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        opacity: 0;
      }

      @media (max-width: 991px) {
        left: 0.16rem;
        top: 50%;
        bottom: auto;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 0.16rem;
        .desc {
          opacity: 1;
        }
        .more {
          display: none;
        }
      }
    }
    .tag {
      display: inline-block;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding: 0 8px;
      background: #e6002e;
      border-radius: 4px;
      color: #fff;
      margin-bottom: 16px;
      @media (max-width: 991px) {
        font-size: 0.12rem;
        line-height: 0.2rem;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        margin-left: 50%;
        white-space: nowrap;
      }
    }
    .title {
      font-weight: 700;
      font-size: 28px;
      line-height: 1;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      .desc {
        padding-top: 20px;
      }

      @media (max-width: 991px) {
        font-size: 0.18rem;
        text-align: center;
        .desc {
          display: none;
        }
      }
    }
    .desc {
      padding-top: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 3;
      // -webkit-box-orient: vertical;
      @media (max-width: 991px) {
        padding-top: 0.13rem;
        font-size: 0.12rem;
        line-height: 0.18rem;
      }
    }

    .more {
      margin-top: 38px;
      // opacity: 0;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
    }

    @media screen and(min-width: 991px) {
      &:hover {
        .cover {
          opacity: 1;
          visibility: visible;
        }
        .info {
          bottom: 32px;
          -webkit-transform: translateY(0);
          transform: translateY(0);
          .title {
            color: #e6002e;
          }
          .desc,
          .more {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
