var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.servers.length > 1)?_c('div',{staticClass:"servers-block"},[_c('div',{staticClass:"tab-hd"},[_vm._l((_vm.servers),function(item,index){return _c('div',{key:index,class:['item', index === _vm.tabIndex && 'cur'],on:{"mouseover":function($event){return _vm.mouseoverFn(index)}}},[_c('div',{staticClass:"th",on:{"click":function($event){$event.stopPropagation();_vm.tabIndex = _vm.tabIndex === index ? '' : index}}},[_c('div',[_vm._v(_vm._s(item.title))]),(_vm.isMobile)?_c('div',{staticClass:"iconfont icon-arrowdown"}):_vm._e(),(_vm.isMobile)?_c('img',{staticClass:"ip",attrs:{"src":"/assets/index/img/ip.png?0814","alt":""}}):_vm._e()]),_c('div',{staticClass:"td"},[_vm._v(_vm._s(item.desc))]),(_vm.isMobile)?_c('div',{staticClass:"tab-bd"},[_c('div',{staticClass:"news-box"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_product_btn_click',
              params: {
                product_name: item.product.name,
              },
            }),expression:"{\n              eventType: 'index_product_btn_click',\n              params: {\n                product_name: item.product.name,\n              },\n            }"}],attrs:{"href":item.news.link,"target":"_blank","rel":"noopener"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${item.news.img}`,"alt":""}}),_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(item.news.title))])])])]),_c('div',{staticClass:"product-box"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_product_btn_click',
              params: {
                product_name: item.product.name,
              },
            }),expression:"{\n              eventType: 'index_product_btn_click',\n              params: {\n                product_name: item.product.name,\n              },\n            }"}],attrs:{"href":item.product.link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"logo-box"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":`/assets/index/img/${item.product.logo}`,"alt":""}})]),_c('div',[_vm._v(_vm._s(item.product.name))])]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.product.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.product.desc))]),_c('div',{staticClass:"tags"},_vm._l((item.product.tag),function(tag){return _c('div',{key:tag},[_vm._v(_vm._s(tag))])}),0),_vm._m(0,true)]),_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_product_btn_click',
              params: {
                product_name: item.product.name,
              },
            }),expression:"{\n              eventType: 'index_product_btn_click',\n              params: {\n                product_name: item.product.name,\n              },\n            }"}],staticClass:"more-line common-828-btn",attrs:{"href":item.link,"target":"_blank","rel":"noopener"}},[_vm._v("查看更多")])])]):_vm._e()])}),_c('img',{staticClass:"ip",style:({ left: _vm.tabIndex * 25 + '%' }),attrs:{"src":"/assets/index/img/ip.png?0814","alt":""}})],2),(!_vm.isMobile)?_c('div',{staticClass:"tab-bd"},[_c('div',{staticClass:"news-box"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
          eventType: 'index_product_btn_click',
          params: {
            product_name: _vm.servers[_vm.tabIndex].product.name,
          },
        }),expression:"{\n          eventType: 'index_product_btn_click',\n          params: {\n            product_name: servers[tabIndex].product.name,\n          },\n        }"}],attrs:{"href":_vm.servers[_vm.tabIndex].news.link,"target":"_blank","rel":"noopener"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.servers[_vm.tabIndex].news.img}`,"alt":""}}),_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(_vm.servers[_vm.tabIndex].news.title))])])])]),_c('div',{staticClass:"product-box"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
          eventType: 'index_product_btn_click',
          params: {
            product_name: _vm.servers[_vm.tabIndex].product.name,
          },
        }),expression:"{\n          eventType: 'index_product_btn_click',\n          params: {\n            product_name: servers[tabIndex].product.name,\n          },\n        }"}],attrs:{"href":_vm.servers[_vm.tabIndex].product.link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"logo-box"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":`/assets/index/img/${_vm.servers[_vm.tabIndex].product.logo}`,"alt":""}})]),_c('div',[_vm._v(_vm._s(_vm.servers[_vm.tabIndex].product.name))])]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.servers[_vm.tabIndex].product.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.servers[_vm.tabIndex].product.desc))]),_c('div',{staticClass:"tags"},_vm._l((_vm.servers[_vm.tabIndex].product.tag),function(tag){return _c('div',{key:tag},[_vm._v(_vm._s(tag))])}),0),_vm._m(1)]),_c('a',{directives:[{name:"track",rawName:"v-track",value:({
          eventType: 'index_product_btn_click',
          params: {
            product_name: _vm.servers[_vm.tabIndex].product.name,
          },
        }),expression:"{\n          eventType: 'index_product_btn_click',\n          params: {\n            product_name: servers[tabIndex].product.name,\n          },\n        }"}],staticClass:"more-line common-828-btn",attrs:{"href":_vm.servers[_vm.tabIndex].link,"target":"_blank","rel":"noopener"}},[_vm._v("查看更多")])])]):_vm._e(),_c('div',{staticClass:"recommend"},[(_vm.isMobile)?_c('img',{staticClass:"label-bg",attrs:{"src":require("../assets/img/item-label-bg.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"item-label"},[(!_vm.isMobile)?_c('img',{staticClass:"label-bg",attrs:{"src":require("../assets/img/item-label-bg.png"),"alt":""}}):_vm._e(),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.business.title))]),_c('a',{attrs:{"href":_vm.business.link,"target":"_blank","rel":"noopener"}})])]),_c('div',{staticClass:"list"},[_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.isMobile ? _vm.swiperOptionH5 : _vm.swiperOption}},[_vm._l((_vm.business.list),function(item,index){return _c('swiper-slide',{key:index,staticClass:"item"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_provider_logo_click',
              params: {
                company_name: item.name,
              },
            }),expression:"{\n              eventType: 'index_provider_logo_click',\n              params: {\n                company_name: item.name,\n              },\n            }"}],attrs:{"href":item.link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"info"},[_c('img',{staticClass:"logo",attrs:{"src":`/assets/index/img/${item.logo}?0818`,"alt":""}})])])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"background":"#fff"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
}]

export { render, staticRenderFns }