var render = function render(){var _vm=this,_c=_vm._self._c;return _c('swiper',{staticClass:"banner-swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.list),function(item,index){return _c('swiper-slide',{key:item.bg + index,staticClass:"banner-slide"},[(_vm.isMobile)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
        eventType: 'index_banner_btn_click',
        params: {
          content_name: item.h1,
        },
      }),expression:"{\n        eventType: 'index_banner_btn_click',\n        params: {\n          content_name: item.h1,\n        },\n      }"}],attrs:{"href":item.link,"target":"_blank"}},[_c('img',{staticClass:"bg-m",attrs:{"src":`/assets/index/img/banner/${item.imgH5}`,"alt":""}})]):_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/banner/${item.bg}`,"alt":""}}),_c('a',{directives:[{name:"track",rawName:"v-track",value:({
        eventType: 'index_banner_btn_click',
        params: {
          content_name: item.h1,
        },
      }),expression:"{\n        eventType: 'index_banner_btn_click',\n        params: {\n          content_name: item.h1,\n        },\n      }"}],attrs:{"href":item.link,"target":"_blank"}},[(!_vm.isMobile)?_c('div',{staticClass:"content-box"},[(item.left)?_c('img',{staticClass:"left-pic",attrs:{"data-swiper-parallax":"-200","src":`/assets/index/img/banner/${item.left}`,"alt":""}}):_vm._e(),(item.right)?_c('img',{staticClass:"right-pic",attrs:{"data-swiper-parallax":"-400","src":`/assets/index/img/banner/${item.right}`,"alt":""}}):_vm._e(),_c('div',{staticClass:"info-text"},[(item.h1)?_c('h1',{attrs:{"data-swiper-parallax":"-300"}},[_vm._v(_vm._s(item.h1))]):_vm._e(),_c('h2',{attrs:{"data-swiper-parallax":"-500"}},[(item.h2)?_c('div',{staticClass:"h2"},[_vm._v(_vm._s(item.h2))]):_vm._e(),(item.h3)?_c('div',{staticClass:"h3"},[_vm._v(_vm._s(item.h3))]):_vm._e()])]),(item.more)?_c('div',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_banner_btn_click',
            params: {
              content_name: item.more,
            },
          }),expression:"{\n            eventType: 'index_banner_btn_click',\n            params: {\n              content_name: item.more,\n            },\n          }"}],staticClass:"more-btn",attrs:{"data-swiper-parallax":"-600"}},[_vm._v(" "+_vm._s(item.more)),_c('span',{staticClass:"iconfont icon-arrowright"})]):_vm._e()]):_vm._e()])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }