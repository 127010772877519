var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"moment-main"},[_c('div',{staticClass:"page-banner"},[(!_vm.isMobile)?_c('img',{staticClass:"bg",attrs:{"src":`/assets/product/img/${_vm.bannerSource.bg}`,"alt":""}}):_c('img',{staticClass:"bg-m",attrs:{"src":`/assets/product/img/${_vm.bannerSource.bgH5}`,"alt":""}}),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"vertical-middle-sm"},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.bannerSource.title))]),_c('p',[_vm._v(_vm._s(_vm.bannerSource.desci))]),_c('div',{staticClass:"type-list"},_vm._l((_vm.bannerSource.children),function(item,index){return _c('div',{directives:[{name:"track",rawName:"v-track",value:({
                eventType: 'collection_page_tap_btn',
                params: {
                  btn_name: item.name,
                },
              }),expression:"{\n                eventType: 'collection_page_tap_btn',\n                params: {\n                  btn_name: item.name,\n                },\n              }"}],key:index + item.name,class:['type', item.path === _vm.curPath && 'cur'],on:{"click":function($event){return _vm.handleTap(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])])]),(_vm.hotVideos.length && _vm.isMobile && _vm.showHot)?_c('div',{staticClass:"moment-hot-video-sw"},[_c('div',{staticClass:"moment-hot-video-s"},[_c('swiper',{ref:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.hotVideos),function(i,index){return _c('swiper-slide',{key:index},[_c('img',{attrs:{"src":`/assets/moment/img/${i.poster}`}}),_c('div',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'collection_page_video_click',
              params: {
                video_name: i.title,
              },
            }),expression:"{\n              eventType: 'collection_page_video_click',\n              params: {\n                video_name: i.title,\n              },\n            }"}],staticClass:"play",on:{"click":function($event){return _vm.showVideo(i.video)}}},[_c('i')]),_c('div',{staticClass:"tit"},[_vm._v(" "+_vm._s(i.title)+" "),_c('div',{staticClass:"page-info"},[_vm._v(" "+_vm._s(index + 1)+"/"+_vm._s(_vm.hotVideos.length)+" ")])])])}),1)],1),_c('div',{staticClass:"swiper-pagination"}),_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.hotVideos.length && !_vm.isMobile)?_c('div',{staticClass:"moment-hot-videos"},[_c('div',{staticClass:"preview-box"},[_c('img',{attrs:{"src":`/assets/moment/img/${_vm.hotVideos[_vm.currentHot].poster}`,"alt":""}}),_c('div',{directives:[{name:"track",rawName:"v-track",value:({
          eventType: 'collection_page_video_click',
          params: {
            video_name: _vm.hotVideos[_vm.currentHot].title,
          },
        }),expression:"{\n          eventType: 'collection_page_video_click',\n          params: {\n            video_name: hotVideos[currentHot].title,\n          },\n        }"}],staticClass:"play",on:{"click":function($event){return _vm.showVideo(_vm.hotVideos[_vm.currentHot].video)}}},[_c('i')]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.hotVideos[_vm.currentHot].desc))])]),_c('div',{staticClass:"vd-list"},[(_vm.hotVideos.length > 4)?_c('div',{staticClass:"prev",on:{"click":function($event){_vm.currentHot === 0
            ? (_vm.currentHot = _vm.hotVideos.length - 1)
            : _vm.currentHot--}}},[_c('i',{staticClass:"iconfont icon-arrowdown"})]):_vm._e(),_c('ul',_vm._l((_vm.hotVideos),function(v,i){return _c('li',{key:i,class:_vm.currentHot === i && 'cur',on:{"click":function($event){_vm.currentHot = i}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(v.title))])])}),0),(_vm.hotVideos.length > 4)?_c('div',{staticClass:"next",on:{"click":function($event){_vm.currentHot === _vm.hotVideos.length - 1
            ? (_vm.currentHot = 0)
            : _vm.currentHot++}}},[_c('i',{staticClass:"iconfont icon-arrowdown"})]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"moment-video-list"},[_c('ul',_vm._l((_vm.videoList),function(v,i){return _c('li',{key:i},[_c('div',{staticClass:"poster"},[_c('img',{attrs:{"src":`/assets/moment/img/${v.poster}`,"alt":""}}),_c('div',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'collection_page_video_click',
              params: {
                video_name: v.title,
              },
            }),expression:"{\n              eventType: 'collection_page_video_click',\n              params: {\n                video_name: v.title,\n              },\n            }"}],staticClass:"play",on:{"click":function($event){return _vm.showVideo(v.video)}}},[_c('i')]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(v.desc))])]),_c('div',{staticClass:"txt"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(v.title))])])])}),0)]),(_vm.showPreview)?_c('div',{staticClass:"pic-preview"},[_c('i',{staticClass:"iconfont icon-guanbi",on:{"click":function($event){_vm.showPreview = false}}}),_c('video',{ref:"video",attrs:{"src":`/assets/moment/video/${_vm.currentVideo}`,"controls":""}})]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prev"},[_c('i',{staticClass:"iconfont icon-arrowleft"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next"},[_c('i',{staticClass:"iconfont icon-arrowright"})])
}]

export { render, staticRenderFns }