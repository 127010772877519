<template>
  <swiper class="banner-swiper" :options="swiperOption">
    <swiper-slide class="banner-slide" v-for="(item, index) in list" :key="item.bg + index">
      <a
        :href="item.link"
        target="_blank"
        v-if="isMobile"
        v-track="{
          eventType: 'index_banner_btn_click',
          params: {
            content_name: item.h1,
          },
        }"
      >
        <img class="bg-m" :src="`/assets/index/img/banner/${item.imgH5}`" alt="" />
      </a>
      <img v-else class="bg" :src="`/assets/index/img/banner/${item.bg}`" alt="" />
      <a
        :href="item.link"
        target="_blank"
        v-track="{
          eventType: 'index_banner_btn_click',
          params: {
            content_name: item.h1,
          },
        }"
      >
        <div class="content-box" v-if="!isMobile">
          <img v-if="item.left" data-swiper-parallax="-200" class="left-pic" :src="`/assets/index/img/banner/${item.left}`" alt="" />
          <img v-if="item.right" data-swiper-parallax="-400" class="right-pic" :src="`/assets/index/img/banner/${item.right}`" alt="" />
          <div class="info-text">
            <h1 v-if="item.h1" data-swiper-parallax="-300">{{ item.h1 }}</h1>
            <h2 data-swiper-parallax="-500">
              <div v-if="item.h2" class="h2">{{ item.h2 }}</div>
              <div v-if="item.h3" class="h3">{{ item.h3 }}</div>
            </h2>
          </div>
          <div
            v-if="item.more"
            data-swiper-parallax="-600"
            class="more-btn"
            v-track="{
              eventType: 'index_banner_btn_click',
              params: {
                content_name: item.more,
              },
            }"
          >
            {{ item.more }}<span class="iconfont icon-arrowright"></span>
          </div>
        </div>
      </a>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    list: Array,
  },

  data() {
    return {
      // list: [],
      isMobile: window.innerWidth < 991,
      swiperOption: {
        speed: 600,
        parallax: true,
        // loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletActiveClass: 'bullet-active',
          bulletClass: 'my-bullet',
        },
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
.my-bullet {
  width: 8px;
  height: 8px;
  background: #d9d9d9;
  border-radius: 4px;
  display: inline-block;
  margin: 0 4px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.bullet-active {
  width: 24px;
  background: #e6002e;
}
@media (max-width: 991px) {
  .my-bullet {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    margin: 0 4px;
  }
  .bullet-active {
    height: 4px;
    width: 16px;
    background: #e6002e;
  }
}

.banner-swiper {
  width: 100%;
  height: 636px;
  @media (max-width: 991px) {
    height: auto;
    min-height: 400px;
  }
  // .swiper-pagination {
  //   bottom: 12px;
  //   .my-bullet {
  //     width: 8px;
  //     height: 8px;
  //     background: #d5d5d5;
  //     &.bullet-active {
  //       background: #e6002e;
  //       width: 24px;
  //     }
  //   }
  // }
  .banner-slide {
    // max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    img.bg,
    img.bg-m {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
    .content-box {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      max-width: 1200px;
      transform: translateX(-50%);
      z-index: 1;
      .left-pic,
      .right-pic {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        top: 0;
      }
      .left-pic {
        left: 0;
      }
      .right-pic {
        right: 0;
      }
      .info-text {
        position: absolute;
        left: 0;
        bottom: 260px;
      }
      h1,
      h2 {
        font-weight: bold;
        font-size: 80px;
        line-height: 1.2;
        padding-bottom: 0;
        color: #fff;
        display: flex;
        align-items: center;
      }
      .h2 {
        padding-right: 30px;
        font-size: 48px;
      }
      .h3 {
        border: 2px solid #fff;
        border-radius: 16px;
        font-size: 38px;
        padding: 4px 12px;
        margin: 2px 0;
        font-weight: normal;
      }
      .more-btn {
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 127px;
        background: #fff;
        color: #e6002e;
        border-radius: 21px;
        padding: 12px 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        cursor: pointer;

        overflow: hidden;
        transition: all 0.4s;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 0;
          background-color: #e6002e;
          background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
          content: '';
          transition: width 0.4s;
          z-index: -1;
          transform: scale(2);
          border-radius: 50%;
        }
        &:hover {
          background: transparent;

          color: #fff !important;
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
