import Vue from "vue";
import VueRouter from "vue-router";
import product from "./product";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    name: "index",
    path: "/index",
    component: () => import("../views/index"),
    meta: {
      title: "828 B2B企业节首页",
    },
  },
  {
    name: "extension",
    path: "/",
    component: () => import("../views/extension"),
    meta: {
      title: "828 B2B企业节首页",
    },
  },
  {
    name: "extensionIndex",
    path: "/extension",
    component: () => import("../views/extension"),
    meta: {
      title: "828 B2B企业节首页",
    },
  },
  {
    name: "search",
    path: "/search",
    component: () => import("../views/search"),
    meta: {
      title: "搜索",
    },
  },
  {
    name: "momentVideos",
    path: "/moment/videos",
    component: () => import("../views/moment/videos"),
    meta: {
      title: "精彩集锦",
    },
  },
  {
    name: "momentPics",
    path: "/moment/pics",
    component: () => import("../views/moment/pics"),
    meta: {
      title: "精彩集锦",
    },
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("../views/privacy-policy"),
    meta: {
      title: "隐私政策声明",
    },
  },
  {
    name: "news",
    path: "/news",
    component: () => import("../views/news"),
    meta: {
      title: "新闻资讯",
      dataUrl: "/assets/news/data.json",
    },
  },
  {
    name: "opportunity",
    path: "/opportunity.html",
    component: () => import("../views/week/opportunity.vue"),
    meta: {
      title: "商机交流周",
    },
  },
  {
    name: "growth",
    path: "/growth.html",
    component: () => import("../views/week/growth.vue"),
    meta: {
      title: "成长加速周",
    },
  },
  {
    name: "experience",
    path: "/experience.html",
    component: () => import("../views/week/digital.vue"),
    meta: {
      title: "经验传递周",
    },
  },
  {
    name: "enabling",
    path: "/enabling.html",
    component: () => import("../views/week/enabling.vue"),
    meta: {
      title: "创新赋能周",
    },
  },
  {
    name: "org",
    path: "/org",
    component: () => import("../views/org"),
    meta: {
      title: "发起单位",
    },
  },
  {
    name: "co-org",
    path: "/co-org",
    component: () => import("../views/co-org"),
    meta: {
      title: "合作机构",
    },
  },
  {
    path: "/zjtx",
    component: () => import("../views/business-service/zjtx"),
    name: "zjtx",
    meta: {
      title: "专精特新",
    },
  },
  {
    path: "/startup",
    component: () => import("../views/business-service/startup"),
    name: "startup",
    meta: {
      title: "企业初创",
    },
  },
  {
    path: "/industry-ec",
    component: () => import("../views/business-service/industry-ec"),
    name: "industry-ec",
    meta: {
      title: "创新发展",
    },
  },
  {
    path: "/go-global",
    component: () => import("../views/business-service/go-global"),
    name: "go-global",
    meta: {
      title: "企业出海",
    },
  },
  {
    name: "case",
    path: "/case",
    component: () => import("../views/case"),
    meta: {
      title: "成功案例",
      dataUrl: "/assets/case/data.json",
    },
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("../views/policy"),
    meta: {
      title: "政策支持",
      dataUrl: "/assets/policy/data.json",
    },
  },
  {
    name: "mutual-aid",
    path: "/mutual-aid",
    component: () => import("../views/mutual-aid"),
    meta: {
      title: "互助中心",
    },
  },
  {
    name: "about",
    path: "/about",
    component: () => import("../views/about"),
    meta: {
      title: "关于828 B2B企业节",
    },
  },
  {
    name: "events",
    path: "/events",
    component: () => import("../views/events"),
    redirect: "/events/announce",
    meta: {
      title: "精彩活动",
    },
    children: [
      {
        path: "live",
        component: () => import("../views/live/live"),
        name: "live",
        meta: {
          title: "828直播间",
        },
      },
      {
        path: "announce",
        component: () => import("../views/events/announce"),
        name: "announce",
        meta: {
          title: "启动仪式",
        },
      },
      {
        path: "opening",
        component: () => import("../views/events/opening"),
        name: "opening",
        meta: {
          title: "开幕式",
        },
      },
      {
        path: "area",
        component: () => import("../views/events/area"),
        name: "area",
        meta: {
          title: "828地方站",
        },
        children: [
          {
            path: "/events/:id",
            component: () => import("../views/events/area"),
          },
        ],
      },
    ],
  },
  ...product,
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.name == "index" && to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "828 B2B企业节";
  Vue.prototype.$hwa.sendPageview({
    title: to.meta.title,
    url: window.location.origin + to.fullPath,
  });
  next();
});

export default router;
