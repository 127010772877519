<template>
  <div class="newproduct-block" style="background-image: url(/assets/index/img/newproduct/bg.jpg)">
    <!-- <img class="bg" src="/assets/index/img/newproduct/bg.jpg" alt="" /> -->
    <div class="recommend">
      <div class="recommend-title">{{ newProduct.title }}</div>
      <div class="product-tab product-tab-m" v-if="isMobile">
        <div :class="['item', tabIndex == index && 'cur']" @click="tabIndex = index" v-for="(item, index) in newProduct.product" :key="item.type">
          <div>{{ item.type }}</div>
        </div>
      </div>
      <swiper class="product-swiper product-tab" :options="swiperOption" v-else>
        <swiper-slide v-for="(item, index) in newProduct.product" :key="item.type">
          <div :class="['item', tabIndex == index && 'cur']" @click="tabIndex = index">
            <div>{{ item.type }}</div>
          </div>
        </swiper-slide>
      </swiper>

      <div class="list">
        <div class="item" v-for="(item, index) in newProduct.product[tabIndex].list" :key="index">
          <div class="item-inner">
            <div class="item-hd">
              <div class="title">
                <a
                  :href="item.business.link"
                  v-track="{
                    eventType: 'index_product_recommend_click',
                    params: {
                      product_name: item.title,
                    },
                  }"
                  target="_blank"
                  rel="noopener"
                  >{{ item.business.name }}
                </a>
              </div>
            </div>
            <a
              :href="item.link"
              target="_blank"
              v-track="{
                eventType: 'index_product_recommend_click',
                params: {
                  product_name: item.title,
                },
              }"
              rel="noopener"
            >
              <div class="item-bd">
                <div class="info">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
                <img class="pic" :src="`/assets/index/img/newproduct/${item.img}`" alt="" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="inner-view-bm">
        <a :href="newProduct.product[tabIndex].link" target="_blank">
          <div class="more-line common-828-btn">{{ newProduct.product[tabIndex].more }}</div></a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    recommend: {
      type: Object,
      default: () => ({
        link: '',
      }),
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true,
        mousewheel: true,
      },
      newProduct: {
        title: '商品推荐',
        product: [
          {
            list: [],
          },
        ],
      },
      // list: [],
      isMobile: window.innerWidth < 991,
      tabIndex: 0,
    };
  },
  mounted() {
    fetch('/assets/index/newproduct.json?' + Math.random()).then((d) => {
      d.json().then((res) => {
        this.newProduct = res;
      });
    });
  },
  methods: {},
};
</script>
<style scoped lang="less">
.newproduct-block {
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1920px;
  margin: 100px auto 0;
  position: relative;
  overflow: hidden;
  .inner-view-bm {
    text-align: right;
    padding: 60px 0;
    .more-line {
      display: inline-block;
      border: 1px solid #ffffff;
      border-radius: 19px;
      padding: 0 20px;
      font-size: 14px;
      line-height: 38px;
      color: #ffffff;
      cursor: pointer;
    }
    @media (max-width: 991px) {
      padding: 0 0 0.28rem;
      .more-line {
        display: block;
        width: 0.91rem;
        text-align: center;
        font-size: 0.12rem;
        padding: 0;
        line-height: 0.28rem;
        margin: 0.24rem auto 0;
        border-radius: 1rem;
      }
    }
  }
  .bg {
    height: 100%;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: 0 auto;
    .bg {
      height: 100%;
    }
  }
  .recommend {
    width: 85.652%;
    max-width: 1424px;
    margin: 0 auto;
    @media (max-width: 991px) {
      width: 100%;
      padding: 0;
      overflow: hidden;
    }
    // position: absolute;
    // top: 0;
    // left: 50%;
    // -webkit-transform: translateX(-50%);
    // transform: translateX(-50%);
    .recommend-title {
      padding: 60px 0;
      font-weight: 700;
      font-size: 44px;
      line-height: 1;
      color: #fff;
      @media (max-width: 991px) {
        margin-left: 0;
        font-size: 0.16rem;
        padding: 0.24rem 0 0.16rem 0;
        text-align: center;
      }
    }
    .product-tab {
      &.product-tab-m {
        overflow-x: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
        padding: 0 0.16rem 0.16rem;
        .item {
          padding: 0.08rem 0.16rem;
          font-size: 0.12rem;
          margin: 0 0.04rem;
        }
      }
      padding-bottom: 36px;

      &::-webkit-scrollbar {
        display: none;
      }

      .item {
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 21px;
        line-height: 1;
        font-size: 18px;
        font-weight: 400;
        padding: 12px 20px;
        margin: 0 12px;
        color: #fff;
        cursor: pointer;
        -webkit-transition: 0.5s all ease-out;
        transition: 0.5s all ease-out;
        white-space: nowrap;

        @media (min-width: 991px) {
          &:hover {
            background-color: #fff;
            color: #a50e37;
            div {
              background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }
        &.cur {
          background-color: #fff;
          color: #a50e37;
          div {
            background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }
    }
    .product-swiper {
      .swiper-slide {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        &:first-child {
          .item {
            margin-left: 0;
          }
        }
        &:last-child {
          .item {
            margin-right: 0;
          }
        }
      }
    }
    .list {
      margin: -8px;
      @media (max-width: 991px) {
        margin: 0;
        padding: 0 0.16rem;
      }
      .item {
        width: 33.333%;
        position: relative;
        display: inline-block;
        vertical-align: top;
        .item-inner {
          background: #ffffff;
          height: 195px;
          margin: 8px;
          border-radius: 8px;
        }
        @media (max-width: 991px) {
          width: 100%;
          .item-inner {
            border-radius: 0.08rem;
            margin: 0 0 0.12rem;
            height: auto;
            padding-bottom: 0.16rem;
          }
        }

        a {
          color: #2a2a2a;
          &:hover {
            .title {
              color: #a50e37;

              background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
            .pic {
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
          }
        }
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        .item-hd {
          padding: 32px;

          .title {
            font-weight: 400;
            font-size: 18px;
            line-height: 1;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
            &:hover {
              color: #a50e37;

              background: linear-gradient(82.41deg, #a50e37 11.76%, #332878 134.01%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
          @media (max-width: 991px) {
            padding: 0.16rem;
            .title {
              font-size: 0.14rem;
            }
          }
        }
        .item-bd {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 0 32px;
          .info {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
          }
          .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 1;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
            @media screen and(min-width: 991px) {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
          .desc {
            margin-top: 13px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .pic {
            -ms-flex-negative: 0;
            flex-shrink: 0;
            margin-left: 20px;
            width: 106px;
            height: 80px;
            border-radius: 8px;
            -o-object-fit: cover;
            object-fit: cover;
            -webkit-transition: all 0.5s;
            transition: all 0.5s;
          }
          @media (max-width: 991px) {
            padding: 0 0.16rem;
            .title {
              font-size: 0.18rem;
              line-height: 0.26rem;
            }
            .desc {
              margin-top: 0.05rem;
              font-size: 0.12rem;
              line-height: 0.18rem;
            }
            .pic {
              width: 0.96rem;
              height: 0.72rem;
            }
          }
        }
      }
    }
  }
}
</style>
