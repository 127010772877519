var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.events.announce.title)?_c('div',{staticClass:"events-block"},[_c('div',{staticClass:"tr"},[_c('div',{staticClass:"item item-announce"},[(_vm.events.announce.link)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
          eventType: 'index_activity_btn_click',
          params: {
            meeting_name: _vm.events.announce.title,
          },
        }),expression:"{\n          eventType: 'index_activity_btn_click',\n          params: {\n            meeting_name: events.announce.title,\n          },\n        }"}],attrs:{"href":_vm.events.announce.link,"target":"_blank","rel":"noopener"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.events.announce.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.events.announce.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.events.announce.desc))]),_vm._m(0)])]):_c('a',{staticStyle:{"cursor":"auto"},attrs:{"href":"javascript:;"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.events.announce.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.events.announce.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.events.announce.desc))]),_vm._m(1)])])]),_c('div',{staticClass:"td-2"},[_c('div',{staticClass:"item"},[(_vm.events.opening.link)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_activity_btn_click',
            params: {
              city_name: _vm.events.opening.title,
            },
          }),expression:"{\n            eventType: 'index_activity_btn_click',\n            params: {\n              city_name: events.opening.title,\n            },\n          }"}],attrs:{"href":_vm.events.opening.link,"target":"_blank","rel":"noopener"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.isMobile ? _vm.events.opening.imgH5 : _vm.events.opening.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.events.opening.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.events.opening.desc))]),_vm._m(2)])]):_c('a',{staticStyle:{"cursor":"auto"},attrs:{"href":"javascript:;"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.isMobile ? _vm.events.opening.imgH5 : _vm.events.opening.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.events.opening.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.events.opening.desc))]),_vm._m(3)])])]),_c('div',{staticClass:"item"},[(_vm.events.area.link)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_activity_btn_click',
            params: {
              city_name: _vm.events.area.title,
            },
          }),expression:"{\n            eventType: 'index_activity_btn_click',\n            params: {\n              city_name: events.area.title,\n            },\n          }"}],attrs:{"href":_vm.events.area.link,"target":"_blank"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.isMobile ? _vm.events.area.imgH5 : _vm.events.area.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.events.area.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.events.area.desc))]),_vm._m(4)])]):_c('a',{staticStyle:{"cursor":"auto"},attrs:{"href":"javascript:;"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.isMobile ? _vm.events.area.imgH5 : _vm.events.area.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.events.area.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.events.area.desc))]),_vm._m(5)])])]),_vm._l((_vm.events.area.site),function(site,index){return [(index < (_vm.isMobile ? _vm.events.area.site.length + 1 : 2))?_c('div',{key:site.link + index,staticClass:"item"},[(site.link)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_activity_btn_click',
              params: {
                city_name: site.name,
              },
            }),expression:"{\n              eventType: 'index_activity_btn_click',\n              params: {\n                city_name: site.name,\n              },\n            }"}],attrs:{"href":site.link,"target":"_blank"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.isMobile ? site.imgH5 : site.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info site"},[_c('div',{staticClass:"tag"},[_vm._v("828地方站")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(site.name))]),_vm._m(6,true)])]):_c('a',{staticStyle:{"cursor":"auto"},attrs:{"href":"javascript:;"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${_vm.isMobile ? site.imgH5 : site.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info site"},[_c('div',{staticClass:"tag"},[_vm._v("828地方站")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(site.name))]),_vm._m(7,true)])])]):_vm._e()]})],2)]),(!_vm.isMobile)?_c('div',{staticClass:"tr"},[_vm._l((_vm.events.area.site),function(site,index){return [(index > 1)?_c('div',{key:index,staticClass:"item"},[(site.link)?_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_activity_btn_click',
            params: {
              city_name: site.name,
            },
          }),expression:"{\n            eventType: 'index_activity_btn_click',\n            params: {\n              city_name: site.name,\n            },\n          }"}],attrs:{"href":site.link,"target":"_blank"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${site.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info site"},[_c('div',{staticClass:"tag"},[_vm._v("828地方站")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(site.name))]),_vm._m(8,true)])]):_c('a',{staticStyle:{"cursor":"auto"},attrs:{"href":"javascript:;"}},[_c('img',{staticClass:"bg",attrs:{"src":`/assets/index/img/${site.img}`,"alt":""}}),_c('div',{staticClass:"cover"}),_c('div',{staticClass:"info site"},[_c('div',{staticClass:"tag"},[_vm._v("828地方站")]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(site.name))]),_vm._m(9,true)])])]):_vm._e()]})],2):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more",staticStyle:{"opacity":"0","margin-top":"0"}},[_c('span',{staticClass:"iconfont icon-arrowright"})])
}]

export { render, staticRenderFns }