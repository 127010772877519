var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newproduct-block",staticStyle:{"background-image":"url(/assets/index/img/newproduct/bg.jpg)"}},[_c('div',{staticClass:"recommend"},[_c('div',{staticClass:"recommend-title"},[_vm._v(_vm._s(_vm.newProduct.title))]),(_vm.isMobile)?_c('div',{staticClass:"product-tab product-tab-m"},_vm._l((_vm.newProduct.product),function(item,index){return _c('div',{key:item.type,class:['item', _vm.tabIndex == index && 'cur'],on:{"click":function($event){_vm.tabIndex = index}}},[_c('div',[_vm._v(_vm._s(item.type))])])}),0):_c('swiper',{staticClass:"product-swiper product-tab",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.newProduct.product),function(item,index){return _c('swiper-slide',{key:item.type},[_c('div',{class:['item', _vm.tabIndex == index && 'cur'],on:{"click":function($event){_vm.tabIndex = index}}},[_c('div',[_vm._v(_vm._s(item.type))])])])}),1),_c('div',{staticClass:"list"},_vm._l((_vm.newProduct.product[_vm.tabIndex].list),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-inner"},[_c('div',{staticClass:"item-hd"},[_c('div',{staticClass:"title"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                  eventType: 'index_product_recommend_click',
                  params: {
                    product_name: item.title,
                  },
                }),expression:"{\n                  eventType: 'index_product_recommend_click',\n                  params: {\n                    product_name: item.title,\n                  },\n                }"}],attrs:{"href":item.business.link,"target":"_blank","rel":"noopener"}},[_vm._v(_vm._s(item.business.name)+" ")])])]),_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_product_recommend_click',
              params: {
                product_name: item.title,
              },
            }),expression:"{\n              eventType: 'index_product_recommend_click',\n              params: {\n                product_name: item.title,\n              },\n            }"}],attrs:{"href":item.link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"item-bd"},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])]),_c('img',{staticClass:"pic",attrs:{"src":`/assets/index/img/newproduct/${item.img}`,"alt":""}})])])])])}),0),_c('div',{staticClass:"inner-view-bm"},[_c('a',{attrs:{"href":_vm.newProduct.product[_vm.tabIndex].link,"target":"_blank"}},[_c('div',{staticClass:"more-line common-828-btn"},[_vm._v(_vm._s(_vm.newProduct.product[_vm.tabIndex].more))])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }