<template>
  <div class="news-block">
    <template v-if="!isMobile && list.length">
      <div class="li-block">
        <div class="item">
          <a
            :href="list[0].link"
            v-track="{
              eventType: 'index_news_btn_click',
              params: {
                news_name: list[0].title,
              },
            }"
            target="_blank"
            rel="noopener"
          >
            <div class="pic"><img :src="`/assets/news/img/${list[0].img}`" /></div>
            <div class="info">
              <div class="date">{{ list[0].date }}</div>
              <div class="title">{{ list[0].title }}</div>
              <div class="desc">{{ list[0].desc }}</div>
            </div>
            <div class="more"><span class="iconfont icon-arrowright"></span></div>
          </a>
        </div>
      </div>
      <div class="li-block">
        <div class="item">
          <a
            :href="list[1].link"
            v-track="{
              eventType: 'index_news_btn_click',
              params: {
                news_name: list[1].title,
              },
            }"
            target="_blank"
            rel="noopener"
          >
            <div class="pic"><img :src="`/assets/news/img/${list[1].img}`" /></div>
            <div class="info">
              <div class="date">{{ list[1].date }}</div>
              <div class="title">{{ list[1].title }}</div>
              <div class="desc">{{ list[1].desc }}</div>
            </div>
            <div class="more"><span class="iconfont icon-arrowright"></span></div>
          </a>
        </div>
      </div>
      <div class="li-block">
        <template v-for="(item, index) in list">
          <div v-if="index > 1 && index < 7" :key="index" class="item">
            <a
              :href="item.link"
              v-track="{
                eventType: 'index_news_btn_click',
                params: {
                  news_name: item.title,
                },
              }"
              target="_blank"
              rel="noopener"
            >
              <div class="info">
                <div class="date">{{ item.date }}</div>
                <div class="title">{{ item.title }}</div>
              </div>
            </a>
          </div>
        </template>
      </div>
    </template>

    <div v-if="isMobile">
      <template v-for="(item, index) in list">
        <div v-if="index < 7" :key="index" class="item">
          <a
            :href="item.link"
            v-track="{
              eventType: 'index_news_btn_click',
              params: {
                news_name: item.title,
              },
            }"
            target="_blank"
            rel="noopener"
          >
            <div class="pic" v-if="index < 2"><img :src="`/assets/news/img/${item.img}`" /></div>
            <div class="info">
              <div class="date">{{ item.date }}</div>
              <div class="title">{{ item.title }}</div>
              <div class="desc" v-if="index < 1">{{ item.desc }}</div>
            </div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {},

  data() {
    return {
      list: [],
      isMobile: document.documentElement.clientWidth < 768,
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      fetch('/assets/news/data.json?' + Math.random()).then((d) => {
        d.json().then((res) => {
          this.list = res[0].list;
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.news-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -20px;

  @media (max-width: 991px) {
    display: block;
    margin: 0;
  }
  .li-block {
    width: 33.3333%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    &:last-child {
      .item {
        .info {
          padding: 0 0 35px;
        }
        .date {
          color: #666;
          font-weight: 400;
        }
        .title {
          padding-top: 9px;
        }
      }
    }
  }
  .item {
    position: relative;
    padding: 0 20px;
    .pic {
      width: 100%;
      height: 306px;
      border-radius: 12px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
      }
    }
    .info {
      padding: 24px;
    }
    .date {
      font-weight: 700;
      font-size: 18px;
      line-height: 1;
      color: #999;
    }
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      padding-top: 17px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }
    .desc {
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      padding-top: 9px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .more {
      margin-left: 24px;
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      opacity: 0;
    }
    a {
      background: #fff;
      color: #2a2a2a;
      text-decoration: none;
      display: block;
      overflow: hidden;
      position: relative;
      height: 100%;
    }
    &:hover {
      .title {
        // color: #e6002e;
        background: linear-gradient(252.44deg, #1b2c85 0%, #e00116 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .pic img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
      .more {
        opacity: 1;
      }
    }
    @media (max-width: 991px) {
      width: 100%;
      padding: 0 0.16rem;
      &:first-child {
        .info {
          margin-bottom: 0.24rem;
        }
      }
      &:last-child {
        .info {
          border: none;
        }
      }
      .pic {
        margin-bottom: -0.08rem;
      }
      .info {
        padding: 0 0.12rem 0.24rem;
        height: auto;
        border-bottom: 1px solid #dedfe0;

        .date {
          font-size: 0.14rem;
          padding-top: 0.24rem;
        }
        .title {
          font-size: 0.18rem;
          line-height: 0.26rem;
          padding-top: 0.12rem;
        }
        .desc {
          font-size: 0.12rem;
          line-height: 0.18rem;
          padding-top: 0.09rem;
        }
      }
    }
  }
}
</style>
