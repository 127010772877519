<template>
  <div class="moment-main">
    <div class="page-banner">
      <img
        v-if="!isMobile"
        :src="`/assets/product/img/${bannerSource.bg}`"
        alt=""
        class="bg"
      />
      <img
        v-else
        :src="`/assets/product/img/${bannerSource.bgH5}`"
        alt=""
        class="bg-m"
      />
      <div class="content-box">
        <div class="vertical-middle-sm">
          <div>
            <h1>{{ bannerSource.title }}</h1>
            <p>{{ bannerSource.desci }}</p>
            <div class="type-list">
              <div
                v-for="(item, index) in bannerSource.children"
                :key="index + item.name"
                v-track="{
                  eventType: 'collection_page_tap_btn',
                  params: {
                    btn_name: item.name,
                  },
                }"
                :class="['type', item.path === curPath && 'cur']"
                @click="handleTap(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="hotVideos.length && isMobile && showHot"
      class="moment-hot-video-sw"
    >
      <div class="moment-hot-video-s">
        <swiper ref="swiper" :options="swiperOption">
          <swiper-slide v-for="(i, index) in hotVideos" :key="index">
            <img :src="`/assets/moment/img/${i.poster}`" />
            <div
              v-track="{
                eventType: 'collection_page_video_click',
                params: {
                  video_name: i.title,
                },
              }"
              class="play"
              @click="showVideo(i.video)"
            >
              <i></i>
            </div>
            <div class="tit">
              {{ i.title }}
              <div class="page-info">
                {{ index + 1 }}/{{ hotVideos.length }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="swiper-pagination"></div>
      <div class="prev">
        <i class="iconfont icon-arrowleft"></i>
      </div>
      <div class="next">
        <i class="iconfont icon-arrowright"></i>
      </div>
    </div>
    <div v-if="hotVideos.length && !isMobile" class="moment-hot-videos">
      <div class="preview-box">
        <img
          :src="`/assets/moment/img/${hotVideos[currentHot].poster}`"
          alt=""
        />
        <div
          v-track="{
            eventType: 'collection_page_video_click',
            params: {
              video_name: hotVideos[currentHot].title,
            },
          }"
          class="play"
          @click="showVideo(hotVideos[currentHot].video)"
        >
          <i></i>
        </div>
        <div class="desc">{{ hotVideos[currentHot].desc }}</div>
      </div>
      <div class="vd-list">
        <div
          v-if="hotVideos.length > 4"
          class="prev"
          @click="
            currentHot === 0
              ? (currentHot = hotVideos.length - 1)
              : currentHot--
          "
        >
          <i class="iconfont icon-arrowdown"></i>
        </div>
        <ul>
          <li
            v-for="(v, i) in hotVideos"
            :key="i"
            :class="currentHot === i && 'cur'"
            @click="currentHot = i"
          >
            <div class="name">{{ v.title }}</div>
          </li>
        </ul>
        <div
          v-if="hotVideos.length > 4"
          class="next"
          @click="
            currentHot === hotVideos.length - 1
              ? (currentHot = 0)
              : currentHot++
          "
        >
          <i class="iconfont icon-arrowdown"></i>
        </div>
      </div>
    </div>
    <div class="moment-video-list">
      <ul>
        <li v-for="(v, i) in videoList" :key="i">
          <div class="poster">
            <img :src="`/assets/moment/img/${v.poster}`" alt="" />
            <div
              v-track="{
                eventType: 'collection_page_video_click',
                params: {
                  video_name: v.title,
                },
              }"
              class="play"
              @click="showVideo(v.video)"
            >
              <i></i>
            </div>
            <div class="desc">{{ v.desc }}</div>
          </div>
          <div class="txt">
            <div class="name">{{ v.title }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="showPreview" class="pic-preview">
      <i class="iconfont icon-guanbi" @click="showPreview = false"></i>
      <video
        ref="video"
        :src="`/assets/moment/video/${currentVideo}`"
        controls
      ></video>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      bannerSource: {},
      isMobile: document.documentElement.clientWidth < 768,
      curPath: "/moment/videos",
      dataUrl: "/assets/moment/videos.json",
      listSource: [],
      hotVideos: [],
      currentHot: 0,
      showHot: false,
      showPreview: false,
      currentVideo: "",
      videoList: [],
      swiperOption: {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "bullet-active",
          bulletClass: "my-bullet",
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
        },
      },
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      fetch(this.dataUrl).then((d) => {
        d.json().then((res) => {
          this.bannerSource = res.bannerSource;
          this.hotVideos = res.hotVideos;
          this.videoList = res.videoList;
          this.$nextTick(() => {
            this.showHot = true;
          });
        });
      });
    },
    handleTap(record) {
      this.$router.push(record.path);
    },
    showVideo(v) {
      this.currentVideo = v;
      this.showPreview = true;
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },
  },
};
</script>

<style lang="less" scoped src="./../../assets/moment.less"></style>
<style lang="less" scoped>
::v-deep {
  .my-bullet {
    width: 8px;
    height: 8px;
    background: #d9d9d9;
    border-radius: 4px;
    display: inline-block;
    margin: 0 4px;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .bullet-active {
    width: 24px;
    background: #e6002e;
  }
}
</style>
