var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list.length || _vm.footer)?_c('div',{staticClass:"page-footer"},[(_vm.isMobile)?_c('div',{staticClass:"coopunit"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:item.type,class:['item', _vm.curIndex === index && 'cur']},[_c('h3',{on:{"click":function($event){_vm.curIndex = _vm.curIndex === index ? '' : index}}},[_c('div',[_vm._v(_vm._s(item.type))]),_c('div',{staticClass:"iconfont icon-arrowdown"})]),_c('ul',_vm._l((item.list),function(sub,idx){return _c('li',{key:idx + sub.link},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_organization_website_click',
              params: {
                content_name: sub.name,
              },
            }),expression:"{\n              eventType: 'index_organization_website_click',\n              params: {\n                content_name: sub.name,\n              },\n            }"}],attrs:{"href":sub.link,"target":"_blank"}},[_vm._v(_vm._s(sub.name))])])}),0)])}),_c('div',{class:['item', _vm.curIndex === 'webList' && 'cur']},[_c('h3',{on:{"click":function($event){_vm.curIndex = _vm.curIndex === 'webList' ? '' : 'webList'}}},[_c('div',[_vm._v("合作网站")]),_c('div',{staticClass:"iconfont icon-arrowdown"})]),_c('ul',[_vm._l((_vm.webList),function(sub,idx){return [(idx < 8)?_c('li',{key:idx + sub.link},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                eventType: 'index_cooperate_website_click',
                params: {
                  content_name: sub.name,
                },
              }),expression:"{\n                eventType: 'index_cooperate_website_click',\n                params: {\n                  content_name: sub.name,\n                },\n              }"}],attrs:{"href":sub.link,"target":"_blank"}},[_vm._v(_vm._s(sub.name))])]):_vm._e()]}),(_vm.footer.more_website.show)?_c('li',[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_cooperate_website_click',
              params: {
                content_name: _vm.footer.more_website.name,
              },
            }),expression:"{\n              eventType: 'index_cooperate_website_click',\n              params: {\n                content_name: footer.more_website.name,\n              },\n            }"}],attrs:{"href":"/co-org","target":"_blank"}},[_vm._v(_vm._s(_vm.footer.more_website.name)),_c('span',{staticClass:"iconfont icon-arrowright"})])]):_vm._e()],2)])],2):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"hav-link"},[_vm._l((_vm.list),function(item){return _c('div',{key:item.type,staticClass:"inner"},[_c('div',{staticClass:"h3"},[_vm._v(_vm._s(item.type))]),_c('ul',_vm._l((item.list),function(sub,idx){return _c('li',{key:idx + sub.link},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_organization_website_click',
              params: {
                content_name: sub.name,
              },
            }),expression:"{\n              eventType: 'index_organization_website_click',\n              params: {\n                content_name: sub.name,\n              },\n            }"}],attrs:{"href":sub.link,"target":"_blank"}},[_vm._v(_vm._s(sub.name))])])}),0)])}),_c('div',{staticClass:"inner"},[_c('div',{staticClass:"h3"},[_vm._v("合作网站")]),_c('ul',[_vm._l((_vm.webList),function(sub,idx){return [(idx < 8)?_c('li',{key:idx + sub.link},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
                eventType: 'index_cooperate_website_click',
                params: {
                  content_name: sub.name,
                },
              }),expression:"{\n                eventType: 'index_cooperate_website_click',\n                params: {\n                  content_name: sub.name,\n                },\n              }"}],attrs:{"href":sub.link,"target":"_blank"}},[_vm._v(_vm._s(sub.name))])]):_vm._e()]}),(_vm.footer.more_website.show)?_c('li',[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_cooperate_website_click',
              params: {
                content_name: _vm.footer.more_website.name,
              },
            }),expression:"{\n              eventType: 'index_cooperate_website_click',\n              params: {\n                content_name: footer.more_website.name,\n              },\n            }"}],attrs:{"href":_vm.footer.more_website.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.footer.more_website.name)),_c('span',{staticClass:"iconfont icon-arrowright"})])]):_vm._e()],2)])],2):_vm._e(),_c('div',{staticClass:"copyright"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(_vm.footer.copyright))]),_c('span',[_vm._v(_vm._s(_vm.footer.beian))])]),_c('div',{staticClass:"link"},[_c('div',[_vm._l((_vm.footer.links),function(item,index){return [_c('a',{key:index,attrs:{"href":item.link}},[_vm._v(_vm._s(item.name))]),(index < _vm.footer.links.length - 1)?_c('span',{key:item.name + index},[_vm._v("|")]):_vm._e()]})],2)])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }