var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news-block"},[(!_vm.isMobile && _vm.list.length)?[_c('div',{staticClass:"li-block"},[_c('div',{staticClass:"item"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_news_btn_click',
            params: {
              news_name: _vm.list[0].title,
            },
          }),expression:"{\n            eventType: 'index_news_btn_click',\n            params: {\n              news_name: list[0].title,\n            },\n          }"}],attrs:{"href":_vm.list[0].link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":`/assets/news/img/${_vm.list[0].img}`}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.list[0].date))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.list[0].title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.list[0].desc))])]),_vm._m(0)])])]),_c('div',{staticClass:"li-block"},[_c('div',{staticClass:"item"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_news_btn_click',
            params: {
              news_name: _vm.list[1].title,
            },
          }),expression:"{\n            eventType: 'index_news_btn_click',\n            params: {\n              news_name: list[1].title,\n            },\n          }"}],attrs:{"href":_vm.list[1].link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":`/assets/news/img/${_vm.list[1].img}`}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.list[1].date))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.list[1].title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.list[1].desc))])]),_vm._m(1)])])]),_c('div',{staticClass:"li-block"},[_vm._l((_vm.list),function(item,index){return [(index > 1 && index < 7)?_c('div',{key:index,staticClass:"item"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
              eventType: 'index_news_btn_click',
              params: {
                news_name: item.title,
              },
            }),expression:"{\n              eventType: 'index_news_btn_click',\n              params: {\n                news_name: item.title,\n              },\n            }"}],attrs:{"href":item.link,"target":"_blank","rel":"noopener"}},[_c('div',{staticClass:"info"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.date))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))])])])]):_vm._e()]})],2)]:_vm._e(),(_vm.isMobile)?_c('div',[_vm._l((_vm.list),function(item,index){return [(index < 7)?_c('div',{key:index,staticClass:"item"},[_c('a',{directives:[{name:"track",rawName:"v-track",value:({
            eventType: 'index_news_btn_click',
            params: {
              news_name: item.title,
            },
          }),expression:"{\n            eventType: 'index_news_btn_click',\n            params: {\n              news_name: item.title,\n            },\n          }"}],attrs:{"href":item.link,"target":"_blank","rel":"noopener"}},[(index < 2)?_c('div',{staticClass:"pic"},[_c('img',{attrs:{"src":`/assets/news/img/${item.img}`}})]):_vm._e(),_c('div',{staticClass:"info"},[_c('div',{staticClass:"date"},[_vm._v(_vm._s(item.date))]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),(index < 1)?_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))]):_vm._e()])])]):_vm._e()]})],2):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('span',{staticClass:"iconfont icon-arrowright"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('span',{staticClass:"iconfont icon-arrowright"})])
}]

export { render, staticRenderFns }